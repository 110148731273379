<template>
  <div>
    <Header :customstyle="{ background: '#0D1912' }">
        <template slot="title">支付方式</template>
    </Header>
    <div class="content">
        <div class="card" v-if="paylist.length > 0">
            <div v-for="(item, index) in paylist" :key="index">
                <div class="cell">
                    <div class="leftcell"  @click="addway">
                        <img v-if="item.pay_type === 1" class="payimg" :src="require('@/assets/images/mine/usdt.png')" />
                        <img v-if="item.pay_type === 2" class="payimg" :src="require('@/assets/images/mine/zhifubao.png')" />
                        <img v-if="item.pay_type === 3" class="payimg" :src="require('@/assets/images/mine/card.png')" />
                        <div class="info">
                            <div class="name">
                                <div v-if="item.pay_type === 1">USDT支付</div>
                                <div v-if="item.pay_type === 2">支付宝</div>
                                <div v-if="item.pay_type === 3">银行卡</div>
                            </div>
                            <div class="mobile">
                                {{item.account}}
                            </div>
                        </div>
                     </div>
                     <van-switch v-model="item.check"  @change="changeSwitch($event, item)" active-color="#27CC7E" inactive-color="#dcdee0" />
                </div>
            </div>
        </div>
        <div class="addcontent">
            <div class="txt">
                请务必使用您本人的实名账户，被激活的交易方式将在交易时间向买方展示，最多激活3种。
            </div>
            <div class="addbtn" @click="addway">
                <img class="addicon" :src="require('@/assets/images/mine/add.png')" />
                <div class="txt">添加</div>
            </div>
        </div>
    </div>
    <van-dialog v-model="showpwd" title="开关支付方式" @cancel="cancel"  @confirm="confirm" show-cancel-button>
        <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
    </van-dialog>
  </div>
</template>

<script>

import Header from "../../components/header.vue";
import {getPaylist, editPayWay} from '@/request/api'
import { Toast } from 'vant';

export default {
    components:{
        Header
    },
    data() {
        return {
            paylist: [],
            showpwd: false,
            password: '',
            tmpselect : {

            },
            address:'',
            userInfo:{},
        }
    },
    activated () {
        this.address = sessionStorage.getItem("address");
        this.userInfo = sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):{};
        this.password = ''
        this.getPayListData() 
    },
    methods: {
        changeSwitch(value, item){
            console.log(value)
            this.tmpselect = item
            this.showpwd = true
        },
        cancel() {
            this.tmpselect.check = !this.tmpselect.check 
        },
        confirm () {
            let _this = this;
            if(!this.address){
                Toast('请使用币安智能链链接')
                return
            }
            if(this.address&&this.userInfo.has_paypwd!=1){
                Toast('请先完善资料,设置安全密码');
                setTimeout(function () {
                    _this.$router.push({
                        path:'/home/mine'
                    })
                })
                return
            }
            if(this.password.length === 0){
                Toast('请输入安全密码')
                this.tmpselect.check = !this.tmpselect.check 
                return;
            }
            const params = {
                address:this.address,
                pay_code:this.password,
                id:this.tmpselect.id
            }
            editPayWay(params).then(res=> {
                if(res.code==200){
                    this.password = ''
                    Toast('操作成功')
                }else if(res.code==10003){
                    Toast('实名认证姓名必须与支付方式姓名一致')
                }else{
                    Toast(res.msg);
                }
            }).catch(err=> {
                 this.tmpselect.check = !this.tmpselect.check 
            })
        },
        getPayListData(){
            if(!this.address){
                Toast('请使用币安智能链链接')
                return
            }
            const params = {
                address:this.address
            }
            getPaylist(params).then(res=>{
                this.paylist = res.data
                this.paylist.filter(item=> {
                    if(item.disable === 0){
                        item.check = true
                    }else {
                        item.check = false
                    }
                })
            })
        },
        addway () {
            this.$router.push({
				path:'/home/mine/addpayway'
			})
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    background: #0C1913;
    min-height: 100vh;
    padding: 32px;
    .card {
        width: 686px;
        min-height: 376px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32,40,35,1);
        .cell {
            display: flex;
            justify-content: space-between;
            padding: 16px 20px;
            .leftcell {
                 display: flex;
                 align-items: center;
            }
            .payimg{
                width: 80px;
                height: 80px;
                opacity: 1;
            }
            .info {
                margin-left: 20px;
                .name {
                    color: rgba(214,255,227,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 40px; 
                }
                .mobile {
                    opacity: 1;
                    color: rgba(139,166,149,1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 36px;
                }
            }
        }
    }
    .addcontent {
        .txt {
            color: rgba(139,166,149,1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 36px;
        }
        .addbtn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top :16px;
            .addicon {
                width: 40px;
                height: 40px;
            }
            .txt {
                margin-left: 10px;
                color: rgba(39,204,127,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 44px;
            }
        }
    }
}
</style>